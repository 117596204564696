@use 'sass:map';
@use '@angular/material' as mat;

// Definición de la paleta personalizada IF
$mat-if: (
  50 : #e0edf2,
  100 : #b3d2de,
  200 : #80b5c9,
  300 : #4d97b3,
  400 : #2680a2,
  500 : #006a92,
  600 : #00628a,
  700 : #00577f,
  800 : #004d75,
  900 : #003c63,
  A100 : #93ccff,
  A200 : #60b4ff,
  A400 : #2d9cff,
  A700 : #1490ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$mat-if-accent: (
  50 : #e0edf2,
  100 : #b3d2de,
  200 : #80b5c9,
  300 : #4d97b3,
  400 : #2680a2,
  500 : #006a92,
  600 : #00628a,
  700 : #00577f,
  800 : #004d75,
  900 : #003c63,
  A100 : #93ccff,
  A200 : #60b4ff,
  A400 : #2d9cff,
  A700 : #1490ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

// Variable para el color blanco del tema
$blanco: map.get(map.get($mat-if, contrast), 500);
// Elimina esta línea que causa el bucle
// @forward 'palette' show $mat-if, $mat-if-accent;

// Variables CSS personalizadas para facilitar su uso
:root {
  // Variables para color primario
  --mi-primary-50: #{map.get($mat-if, 50)};
  --mi-primary-100: #{map.get($mat-if, 100)};
  --mi-primary-200: #{map.get($mat-if, 200)};
  --mi-primary-300: #{map.get($mat-if, 300)};
  --mi-primary-400: #{map.get($mat-if, 400)};
  --mi-primary-500: #{map.get($mat-if, 500)};
  --mi-primary-600: #{map.get($mat-if, 600)};
  --mi-primary-700: #{map.get($mat-if, 700)};
  --mi-primary-800: #{map.get($mat-if, 800)};
  --mi-primary-900: #{map.get($mat-if, 900)};
  
  // Variables para color de acento
  --mi-accent-500: #{map.get($mat-if-accent, 500)};
}
$max-size-tablet: 840px;
$max-size-phone: 480px;

$default-block-separation: 1.5em;
$default-input-separation: 1em;

@mixin media-desktop {
  @media (min-width: #{$max-size-tablet}) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: #{$max-size-phone}) and (max-width: #{$max-size-tablet - 1px}) {
    @content;
  }
}

@mixin media-phone {
  @media (max-width: #{$max-size-phone - 1px}) {
    @content;
  }
}




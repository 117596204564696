@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:math';
@use 'palette' as p;
// Importaciones
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700&display=swap');
@import 'utils';
@import "../node_modules/@material/layout-grid/mdc-layout-grid";
@import "if-theme";
@import "variables";

.hidden-phone {
  display: inherit;
  @include media-phone {
    display: none;
  }
}

.hidden-tablet {
  display: inherit;
  @include media-tablet {
    display: none;
  }
}

.visible-desktop {
  display: none;
  @include media-desktop {
    display: inherit;
  }
}
.visible-phone {
  display: none;
  @include media-phone {
    display: inherit;
  }
}

body, html {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: 100%;
}

.pointer{
  cursor: pointer;
}

*:focus {
  outline: none;
}

.mat-raised-button:hover {
  filter: saturate(2);
}

.mat-mdc-cell {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.hidden {
  visibility: hidden;
}
.full-width {
  width: 100%;
}
.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


/////////////////////////
// Generales
/////////////////////////

.text-centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

img {
  max-width: 100%;
  height: auto;
}

.float-right {
  float: right;
}

.clear-float {
  clear: both;
}

.inline-flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.between-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

h2 {
  color: var(--mi-primary-400);
}

// Separaciones
.margin-bottom {
  margin-bottom: $default-block-separation;
}

.margin-input {
  margin-top: $default-input-separation;
}

.margin-inline {
  margin: 0 $default-input-separation;
}

.button-group {
  margin: 2em 0 1em 0;
}

.padding-inside-box {
  padding: $default-input-separation;
}

.relleno-flex {
  flex: 1 1 auto;
}


/////////////////////////
// Específicas
/////////////////////////

// Imagen de profesor y alumno
#photo {
  position: relative;

  img {
    max-height: 200px;
  }

  a {
    &>span {
      background-color: var(--mi-primary-50);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.4s;

      &>span {
        @extend .mat-button;
        @extend .mat-button, .mat-primary;


        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        margin-top: -1em;
      }
    }

    &:hover > span {
      opacity: 0.7;
    }
  }
}

// Loading
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

// Loading Student Info
.loading-student-data {
  color: #2680a2;
  font-size: 1.5em;
  text-align: center;
  height: 50px;
  position: relative;
  margin: 5%;
}


.cargando-archivo {
  display: flex;
  color: #006a92;
  mat-spinner {
    margin-right: 5px;
    width: 20px;
  }

  .texto {
    margin-top: 5px;
    font-weight: bold;
  }
}


// Pagos de alojamientos
#pagos {
  > div {
    max-width: 50em;
    margin: auto;
  }

  mat-expansion-panel-header .mat-content {
    display: flex;

    // .pago-mes {
    //   min-width: 9em;
    // }

    .pago-precio {
      // min-width: 6em;
      text-align: right;
      padding-right: 2em;
    }
  }

  ul.pago-alumnos {
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 48px;
      padding: 0 16px;

      &:hover {
        background-color: var(--mi-primary-50);
      }

      // .pago-nombre {
      // }

      .pago-calculo {
        text-align: right;
      }
    }
  }
}


/////////////////////////
// Forms
/////////////////////////
form .mat-mdc-select-value-text,
form .mat-mdc-input-element,
form .mat-mdc-form-field {
  font-size: 14px;
}
// form .mat-mdc-form-field {
//   width: 100%;
//   padding-bottom: 0.5em;
// }

.mat-mdc-form-field {
  width: 100%;
  padding-bottom: 0.5em;
}


.form-field {
  @extend .mat-mdc-form-field;
}
// Evita cambio de color en el select deshabilitado
.mat-mdc-select-disabled .mat-mdc-select-value, 
.mat-mdc-select-disabled .mat-mdc-select-arrow,
.mat-form-field-disabled .mat-mdc-select-value-text,
.mat-form-field-disabled .mat-mdc-select-value,
.mat-form-field-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 1 !important;
}

// Oculta la flecha del select en modo deshabilitado
.mat-mdc-select-disabled .mat-mdc-select-arrow {
  opacity: 0 !important;
}

// Mantiene el color del texto en selects deshabilitados
.mat-mdc-form-field-disabled .mdc-text-field--disabled .mat-mdc-select-value-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

// Cambia el color del borde del select desactivado
.mat-mdc-form-field-disabled .mdc-notched-outline__leading,
.mat-mdc-form-field-disabled .mdc-notched-outline__notch,
.mat-mdc-form-field-disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
  // border-width: 1px !important;
}

// Evita el cambio de color del borde en hover
.mat-mdc-form-field-disabled:hover .mdc-notched-outline__leading,
.mat-mdc-form-field-disabled:hover .mdc-notched-outline__notch,
.mat-mdc-form-field-disabled:hover .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
}

// Evita el cambio de color del borde en focus
.mat-mdc-form-field-disabled.mat-focused .mdc-notched-outline__leading,
.mat-mdc-form-field-disabled.mat-focused .mdc-notched-outline__notch,
.mat-mdc-form-field-disabled.mat-focused .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
  // border-width: 1px !important;
}

/* Corrige el color del borde para campos deshabilitados */
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
  // border-width: 1px !important;
}

/* Estilos específicos para textarea deshabilitados */
textarea.mat-mdc-input-element:disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 1 !important;
  background-color: transparent !important;
}

/* Asegura que los textarea deshabilitados mantengan el mismo estilo que los inputs */
.mat-mdc-form-field-disabled textarea.mat-mdc-input-element {
  color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(0, 0, 0, 0.42) !important;
}

/* Deshabilita el efecto de focus en inputs readonly */
.mat-mdc-form-field:has(input[readonly]) .mdc-notched-outline__leading,
.mat-mdc-form-field:has(input[readonly]) .mdc-notched-outline__notch,
.mat-mdc-form-field:has(input[readonly]) .mdc-notched-outline__trailing,
.mat-mdc-form-field:has(textarea[readonly]) .mdc-notched-outline__leading,
.mat-mdc-form-field:has(textarea[readonly]) .mdc-notched-outline__notch,
.mat-mdc-form-field:has(textarea[readonly]) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
  border-width: 1px !important;
}

/* Evita el cambio de color del borde en focus para elementos readonly */
.mat-mdc-form-field:has(input[readonly]).mat-focused .mdc-notched-outline__leading,
.mat-mdc-form-field:has(input[readonly]).mat-focused .mdc-notched-outline__notch,
.mat-mdc-form-field:has(input[readonly]).mat-focused .mdc-notched-outline__trailing,
.mat-mdc-form-field:has(textarea[readonly]).mat-focused .mdc-notched-outline__leading,
.mat-mdc-form-field:has(textarea[readonly]).mat-focused .mdc-notched-outline__notch,
.mat-mdc-form-field:has(textarea[readonly]).mat-focused .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
  border-width: 1px !important;
}

/* Elimina el efecto de hover en elementos readonly */
.mat-mdc-form-field:has(input[readonly]):hover .mdc-notched-outline__leading,
.mat-mdc-form-field:has(input[readonly]):hover .mdc-notched-outline__notch,
.mat-mdc-form-field:has(input[readonly]):hover .mdc-notched-outline__trailing,
.mat-mdc-form-field:has(textarea[readonly]):hover .mdc-notched-outline__leading,
.mat-mdc-form-field:has(textarea[readonly]):hover .mdc-notched-outline__notch,
.mat-mdc-form-field:has(textarea[readonly]):hover .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.42) !important;
}

/* Mantiene el color normal de las etiquetas en campos disabled y readonly */
.mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.mat-mdc-form-field:has(input[readonly]) .mat-mdc-form-field-label,
.mat-mdc-form-field:has(textarea[readonly]) .mat-mdc-form-field-label,
.mat-mdc-form-field-disabled .mdc-floating-label,
.mat-mdc-form-field:has(input[readonly]) .mdc-floating-label,
.mat-mdc-form-field:has(textarea[readonly]) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38) !important;
}

/* Mantiene el color normal de las etiquetas en campos disabled y readonly cuando están flotando */
.mat-mdc-form-field-disabled .mdc-floating-label--float-above,
.mat-mdc-form-field:has(input[readonly]) .mdc-floating-label--float-above,
.mat-mdc-form-field:has(textarea[readonly]) .mdc-floating-label--float-above {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-mdc-form-field:has(input[readonly]).mat-focused .mdc-notched-outline__notch,
.mat-mdc-form-field:has(textarea[readonly]).mat-focused .mdc-notched-outline__notch,
.mat-mdc-form-field:has(input[readonly]) .mdc-notched-outline__notch,
.mat-mdc-form-field:has(textarea[readonly]) .mdc-notched-outline__notch,
.mat-mdc-form-field:has(input[readonly]):hover .mdc-notched-outline__notch,
.mat-mdc-form-field:has(textarea[readonly]):hover .mdc-notched-outline__notch {
  border-left: none !important;
}

// /* Quita el cursor de texto en inputs y textareas readonly */
// input[readonly],
// textarea[readonly] {
//   cursor: default !important;
//   pointer-events: none !important;
// }

// /* Asegura que los inputs y textareas readonly no tengan apariencia de ser editables */
// .mat-mdc-form-field:has(input[readonly]) .mat-mdc-text-field-wrapper,
// .mat-mdc-form-field:has(textarea[readonly]) .mat-mdc-text-field-wrapper {
//   cursor: default !important;
// }

// Muestra scroll en tablas que no caben
.mat-mdc-table {
  overflow: auto;
}
// Relleno mínimo para que no se peguen los datos de las celdas
.mat-mdc-cell,
.mat-mdc-header-cell {
  padding: 1rem 0.5rem;
}

// Radio buttons
.mat-radio-group.radio-group,
.radio-group .mat-mdc-radio-group {
  display: inline-flex;
  padding: 0.5em 0 0.5em;
  flex-direction: column;

  .radio-button {
    margin-bottom: 0.5em;
  }
}
.radio-group>label {
  margin-right: 0.5em;
}

// Checkbox
.form-option label {
  line-height: 24px;
  cursor: pointer;

  .mat-checkbox-inner-container-no-side-margin {
    margin-right: 8px;
  }
}

// Checkbox en cabecera
.head-check {
  @extend .mat-mdc-form-field;
  width: auto;
  margin-right: 2em;
}


// Fieldset
form fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  margin: 1em auto;
  border-radius: 4px;

  legend {
    font-size: 16px;
    padding: 0 0.5em;
  }

  .more-legends {
    font-size: 1.17em;
    padding: 0 0.5em;
    margin-top: 3em;
  }
}

// Textarea
textarea.mat-input-element {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom: none;
  padding: 0.5em 0.5em 0;
  box-sizing: border-box;
}

// Eliminado espacio superior del formulario
form .mdc-layout-grid__cell h2:first-child {
  margin-top: 0;
}

// Botones con icono
button.mat-button .mat-icon,
button.mat-raised-button .mat-icon {
  margin-left: 0.5rem;
}


// Errores
.mat-snack-bar-container {
  &.snack-bar-error {
    background: rgb(234, 204, 204) /* $_umbra-color */;
    padding: 24px;
    color: mat.m2-get-color-from-palette($candy-app-warn);
    font-weight: bold;
  }
}

.alert {
  color: mat.m2-get-color-from-palette($candy-app-warn);
}


////////////////////////
// Navigation
////////////////////////
.navigation {
  .mat-mdc-card {
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    background-color: var(--mi-primary-300);
    color: p.$blanco;
    padding: 24px;
    &:hover {
      background-color: var(--mi-primary-500);
    }
  }
}


////////////////////////
// Modal
////////////////////////
.modal {
  .header {
    display: flex;
    justify-content: space-between;

    h1 {
      margin-top: 0;
      color: var(--mi-primary-400);
    }

    .mat-icon-button {
      margin-top: -1em;
      margin-right: -1em;
    }
  }

  .mat-mdc-form-field-type-mat-select {
    max-width: 40em;
    display: block;
  }
}


////////////////////////
// Tables
////////////////////////
.table-check {
  width: 100%;
  margin-bottom: $default-block-separation;
  border-collapse: collapse;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  th {
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 500;
  }

  th,
  td {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    min-height: 48px;
    padding: 1rem 0.5rem;
  }

  tbody tr {
    &:hover {
      background-color: var(--mi-primary-50);
    }
  }
}

.without-last-border .mat-table,
.without-last-border.mat-table {
  .mat-mdc-row:last-of-type {
    border: none;
  }
}
////////////////////////
// Tables Angular Material
////////////////////////
// Estilos básicos para tablas Angular Material 18
.mat-mdc-table {
  width: 100%;
  background: #fff;
  
  // Cabecera
  .mat-mdc-header-row {
    // background-color: #f5f5f5;
    min-height: 56px;
  }
  
  .mat-mdc-header-cell {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    // font-weight: 500;
    padding: 0 16px;
    
    &:first-child {
      padding-left: 24px;
    }
    
    &:last-child {
      padding-right: 24px;
    }
  }

  // Celdas
  .mat-mdc-row {
    min-height: 48px;
    
    &:hover {
      background-color: var(--mi-primary-50);
    }
  }

  .mat-mdc-cell {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    
    &:first-child {
      padding-left: 24px;
    }
    
    &:last-child {
      padding-right: 24px;
    }
  }

  // Estados de la fila
  .mat-mdc-row {
    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }
    
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  // Alineación de columnas específicas
  .mat-column-actions {
    width: 100px;
    text-align: center;
  }

  .mat-column-number,
  .mat-column-date {
    width: 120px;
  }
}

.mat-mdc-row {
  &:hover {
    background-color: var(--mi-primary-50);
  }
}

// Botones de acción
.mat-column-actions a {
  .mat-icon {
    color: map.get(mat.$m2-grey-palette, 700);

    &:hover {
      color: rgba(var(--mi-primary-500-rgb), 1);
    }
  }

  &.disabled {
    .mat-icon,
    .mat-icon:hover {
      color: map.get(mat.$m2-grey-palette, 300);
      cursor: auto;
    }
  }

}

// Columnas
.mat-table {

  .mat-mdc-cell:last-child,
  .mat-header-cell:last-child {
    flex: 0 0 6rem;
    text-align: center;
  }

  // Tabla de cursos matriculados
  &.cursos-matriculados {
    .mat-column-mark,
    .mat-column-mark_letter {
      flex: 0 0 5rem;
      text-align: center;
    }
  }
}

// Tabla de alumnos de la edición
.alumnos-edicion {
  .mat-column-SA_level_test_mark {
    flex: 0 0 8rem;
    text-align: center;
  }
  .mat-column-passport {
    flex: 0 0 7rem;
  }
}

// Tabla de estudiantes en alojamiento
.estudiantes-alojamiento {
  .mat-column-day_price {
    flex: 0 0 10rem;
    text-align: center;
  }
}

// Tabla de cursos con créditos
.cursos-con-creditos {
  .mat-column-credits {
    flex: 0 0 12rem;
    text-align: center;
  }
}

// Tabla de usuarios, columna is_active
mat-header-cell.mat-column-is_active,
mat-cell.mat-column-is_active {
  flex: 0 0 7rem;
  text-align: center;
}

// Columna de fechas
mat-header-cell.mat-column-date,
mat-cell.mat-column-date {
  flex: 0 0 7rem;
}

// Estilos base actualizados para Material 18
.mdc-button {
  &.mat-mdc-button {
    min-width: 88px;
    letter-spacing: 0.01em;
  }
}

.mat-mdc-table {
  .mat-mdc-cell {
    padding: 1rem 16px;
  }
}

// Actualizar selectores antiguos
.mat-button {
  @extend .mat-mdc-button;
}

.mat-raised-button {
  @extend .mat-mdc-raised-button;
}

.mat-mdc-cell {
  @extend .mat-mdc-cell;
}
